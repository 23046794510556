@use 'sass:math';
@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.units_container {
  margin-bottom: math.div($margin-m, 2);
  width: 25%;
}

.indicator {
  align-self: center;
  margin: 0 4px;
}

.selectField {
  width: 250px;
}

.red {
  color: $color-error;
}
